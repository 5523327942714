<template>
  <div>
     <b-modal ref="my-modal" hide-footer title="Enter Otp to activate">
       <form @submit.prevent="submitOtp()">
         <div class="row">
           <div class="col-md-12">
             <input type="number" class="form-control" v-model="otp" min="0" required>
           </div>
         </div>
         <div class="form-group">
           <button class="mt-3 btn btn-outline-primary" type="submit">Submit</button>
           <button class="mt-3 ml-3 btn btn-outline-success" type="button" @click="resendSms()">Resend Otp?</button>
         </div>
       </form>

    </b-modal>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <px-card>
            <div slot="with-padding">
              <div class="row">
                <div class="col-md-2">
                  <h5>Members List </h5>
                </div>
                <div class="col-md-4">
                  <b-input-group class="border rounded-pill p-1">
                    <div class="input-group-prepend border-0">
                      <button id="button-addon4" type="button" class="btn btn-link text-info"><i class="fa fa-search"></i></button>
                    </div>
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Type phone number to Search"
                        aria-describedby="button-addon4"
                        class="form-control bg-none border-0"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <div class="table-responsive datatable-vue">
                <b-table
                    striped hover
                    :items="users.data"
                    :fields="fields"
                    :per-page="perPage"
                >
                    <template #cell(actions)="row">
                    </template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination v-if="users.data && users.data.length >= 1"
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              @change="handlePageChange"
                              class="my-0"
                ></b-pagination>
                <div class="text-center" v-else>
                  No Records Found
                </div>
              </b-col>
            </div>
          </px-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getRequestParams} from "@/helpers/pagination";
import {member_fields} from "@/pages/customers/fields/member_field_defs";
import Edit from "@/pages/customers/Edit.vue";
import axios from "axios";

export default {
  components:{
    'edit-customer':Edit
  },
  data() {
    return {
      otp:'',
      customer:{},
      fields: member_fields,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 150, 200],
      searchTitle: "",
      show_edit:false
    }
  },
  mounted() {
    this.retrieveUsers();
    this.$store.dispatch('updateStats');

    bus.$on('hide_edit_form',()=>{
      this.show_edit = false;
    })
  },
  computed:{
    ...mapGetters({users:'getCustomers','stats':'getStats'}),
  },
  watch: {
    users() {
      this.totalRows = this.users.meta.total;
    },
    searchTitle() {
      this.retrieveUsers();
    },
  },
  methods:{
    submitOtp() {
      axios.post('/membership/account/activate',{account_id:this.customer.user_id,otp_value:this.otp})
          .then(res =>{
            console.log('success response');
            this.success_alert_state()
          }).catch(err => {
        this.danger_alert_state('Whoops! Something wrong happened.')
        console.log(err.error)
      })
    },

    resendSms() {
      axios.get(`/baas/resend-sms/${this.customer.user_id}`)
          .then(res =>{
            console.log('success response');
          }).catch(err => {
        this.danger_alert_state('Whoops! Something wrong happened.')
        console.log(err.error)
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    activateCustomer(customer) {
      this.customer = customer;
      this.$refs['my-modal'].show();
      axios.post(`/customer/create-bank-account/${customer.user_id}`)
          .then(res=> {
          }).catch(err => {
            console.log(err)
         this.danger_alert_state('Whoops! Something wrong happened.')
      })

    },

    editCustomer(customer) {
      bus.$emit('show_edit_form',customer)
    },
    retrieveUsers() {
      const params = getRequestParams(
          this.searchTitle,
          this.currentPage,
          this.perPage
      );
      this.$store.dispatch('updateCustomers',params)
    },
    handlePageChange(value) {
      this.currentPage = value
      this.retrieveUsers();
    },
    success_alert_state:function(){
      this.$swal({
        title:"Good job!",
        text:"Customer Account Successfully Activated.",
        type:'success'
      });
    },
    danger_alert_state:function(message){
      this.$swal({
        title:"Error Occurred!",
        text:message,
        type:'error'
      });
    }
  },
}
</script>

<style scoped>
.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.card {
  box-shadow: 7px 7px 7px 7px rgba(50, 50, 50, 0.5) !important;
}
.card_green {
  color: #63D926 !important;
}
</style>
